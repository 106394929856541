import {Label, Main} from "../Stripe/CardSectionStyles";
import {DatePicker, Input, message, Popconfirm} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, {useState} from "react";
import axios from "axios";


const AddMeeting = () => {

  const stylesInput = {
    marginBottom: 10, padding: 10, borderRadius: 5,
    width: '-webkit-fill-available'
  }

  const [state, setState] = useState({
    title: '',
    location: '',
    date: '',
    price: 0,
    otherComments: '',
  });



  const updateField = e => {
    e.persist();
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  function onChangeAmount(e) {
    e.persist();
    if (!e.target.validity.patternMismatch) {
      setState({...state, price: e.target.value});
    }
  }

  async function confirm() {

    await axios.post('/meeting',
      {
        data: state
      })
      .then((e) => {
        console.log(e.data)
      }).then(() => {

        message.success('Meeting successfully created',2)
          .then(()=>
            message.info("Page will be refreshed",2))
          .then(()=>{
            window.location.reload(false);
          }).then(()=>{
        });

      })
      .catch(e => {
        console.log(e)
      });
  }

  const dateFormat = 'YYYY/MM/DD';

  return (
    <>
      <form style={{marginTop: 20}}>
        <div className={'hover-border'} style={Main}>
          <div style={Label}>Create a new meeting</div>
          <Input
            style={stylesInput}
            value={state.title}
            name={'title'}
            placeholder={'Title'}
            onChange={updateField}
          />
          <Input
            style={stylesInput}
            name={'location'}
            onChange={updateField}
            placeholder={'Location'}
          />

          <DatePicker
            showTime={false}
            onChange={(e) => setState({
              ...state,
              date: e._d.toDateString()
            })}
            style={stylesInput}
            format={dateFormat}
          />

          <Input
            style={stylesInput}
            value={state.price || ''}
            pattern="^-?[0-9]\d*\.?\d*$"
            placeholder={'Price'}
            onChange={onChangeAmount}

          />
          <TextArea
            style={stylesInput}
            name={'otherComments'}
            placeholder={"Description"}
            onChange={updateField}
          />
          <Popconfirm placement="top" title={"Free of errors?"}
                      onConfirm={confirm}
                      okText="Yes" cancelText="No">

            <button
              className="amplify-button "
              data-fullwidth="true"
              data-loading="false"
              data-variation="primary"
              type="submit"
              style={{background: "#0c6030"}}
            >
              Submit
            </button>

          </Popconfirm>
        </div>
      </form>

    </>)
}

export default AddMeeting;
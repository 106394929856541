import React from 'react';
import {useHistory} from "react-router-dom";
import RegistrationPayment from "../Components/Stripe/RegistrationPayment";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";

function RegistrationPage() {
  const stripePromise = loadStripe(
    'pk_live_51K8V7sGI0OEgB81vQotRtLrD8Rwkfx2vjTouiRnE910WbxJ1akBbDaeyZkY7nN6fKnPIheL0drDJsbQefZwkgYmH00dVJPcXZ6');
  const history = useHistory();
  if (history.location.state === undefined) {
    history.push('/meetings')
  }


  return history.location.state === undefined?<></>: (
    <Elements stripe={stripePromise}>
      <RegistrationPayment
        location={history.location.state.location}
        amount={history.location.state.amount}
        date={history.location.state.date}
        purpose={history.location.state.purpose}
      />
    </Elements>


  );
}

export default RegistrationPage;
import {
  GET_REGISTRATION,
  SET_REGISTRATION,
  START_REGISTRATION,
  STOP_REGISTRATION,
  SUCCESS_REGISTRATION
} from "./register.types";

const initialState = {
  name:'',
  affiliation:'',
  price:'',
  date:'',
  location:'',
  isRegistering: false,
  isSuccessRegistration:false
}

export const registerReducer = (state = initialState, action) => {

  switch (action.type) {

    case START_REGISTRATION:
      return {
        ...state,
        isRegistering: true,
        isSuccessRegistration:false
      }

    case GET_REGISTRATION:
      return {
        ...action.payload,
        isRegistering: false,
        isSuccessRegistration:false
      };
    case SET_REGISTRATION:
      return {
        ...action.payload,
        isRegistering: true,
        isSuccessRegistration:false
      };
    case STOP_REGISTRATION:
      return {
        initialState
      };
    case SUCCESS_REGISTRATION:
      return {
        ...action.payload,
        isRegistering: false,
        isSuccessRegistration:true
      }

    default:
      return state;
  }
}

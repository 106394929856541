import {FILTER_DATA, RESET_DATA} from "./searchBox.types";

const initialState = {
  filteredData: []
};

const searchBoxReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_DATA:
      return {
        ...state,
        filteredData: action.payload
      }
    case RESET_DATA: {
      return initialState
    }
    default:
      return state
  }
};
export default searchBoxReducer;
import axios from "axios";
import {message} from "antd";

export const createCustomerIfNotExists = async (user, typeOfCustomer) => {

  let newCustomer;
  try {
    newCustomer = await axios.post(
      '/customer',
      {
        id: user.id,
        email: user.email,
        name: user.name,
        description: typeOfCustomer,
      });
  } catch (e) {
    console.log(e)
  }
  return newCustomer;
};


export const createCharge = async (
  name,
  result,
  stripeConfig,
  amount,
  purposeOfPay) => {

  try {
    await axios.post(
      '/charge',
      {
        token: result.token,
        charge: {
          currency: stripeConfig.currency,
          amount: amount * 100,
          description: purposeOfPay,
        },
      });

  } catch (e) {
    console.log(e);
    message.error('Your payment could not be processed')
  }
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'antd/dist/antd.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import ReduxThunk from 'redux-thunk';
import {createStore, applyMiddleware, combineReducers} from 'redux';
import userReducer from "./redux/user/user.reducer";
import {meetingsReducer} from "./redux/meeting/meetings.reducer";
import {registerReducer} from "./redux/meetingRegister/register.reducer";
import videoReducer from "./redux/video/video.reducer";
import logger from "redux-logger";
import searchBoxReducer from "./redux/searchBox/searchBox.reducer";



const appReducers = combineReducers({
  user: userReducer,
  meetings: meetingsReducer,
  register: registerReducer,
  video:videoReducer,
  search:searchBoxReducer
});

const store = process.env.NODE_ENV === 'development' ?
  createStore(appReducers,
    applyMiddleware(ReduxThunk, logger)) :
  createStore(appReducers,
    applyMiddleware(ReduxThunk));

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

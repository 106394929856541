import React, {useEffect, useState} from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import {Main} from "../../Stripe/CardSectionStyles";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useHistory} from "react-router-dom";
import {faFileUpload} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {Menu, message} from "antd";
import LoaderOverlay from "../../LoaderOverlay";


const DocUpload = () => {
  const [current, setCurrent] = useState('');
  const [isSending, setIsSending] = useState(false);

  const history = useHistory();

  if (history.location.state === undefined) {
    history.push('/');
  }

  useEffect(() => {
    setCurrent(history.location.state.doc)
  }, [history.location.state.doc]);


  const handleSubmit = async (files, allFiles) => {
    // return
    const arr = files.map(f => f.file)
    let formData = new FormData();
    for (const key of Object.keys(arr)) {
      formData.append('image', arr[key])
    }

    formData.append('category', current);
    setIsSending(true);

    try {
      const result = await axios.post('/docs', formData);
      console.log(result);
    } catch (e) {
      message.error('error')
    }
    allFiles.forEach(f => f.remove())
    setIsSending(false);
    message.success(`Successfully Sent:(${displayCategory()})`)
  }

  const displayCategory = () => {
    try {
      return current.replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => {
          return str.toUpperCase();
        });
    } catch (e) {
      //in case the page rendered without history
      history.push('/');
    }
  }

  function handleClick(e) {
    setCurrent(e.key)
  }


  return (
    <>
      <div style={{position: '', margin: 'auto', maxWidth: 1000}}>
        <Menu
          onClick={handleClick}
          mode="horizontal"
          style={{fontSize: 12}}
          selectedKeys={[current]}
        >
          <Menu.Item key="taxes">Taxes</Menu.Item>
          <Menu.Item key="statements">Statements</Menu.Item>
          <Menu.Item key="paidReceipts">Paid Receipts</Menu.Item>
          <Menu.Item key="unpaidReceipts">Unpaid Receipts</Menu.Item>
          <Menu.Item key="other">Other</Menu.Item>
        </Menu>
      </div>

      {isSending && <LoaderOverlay text={isSending ? 'please wait..' : ''}/>}

      <div style={{marginTop: 20}}>

        <div style={Main}>
          <FontAwesomeIcon icon={faFileUpload} style={{color: 'darkorange'}}/>{' '}
          {displayCategory()}
          <Dropzone
            onSubmit={handleSubmit}
            disabled={isSending}
            accept={'image/*,application/*,text/*'}
            styles={{
              dropzone: {
                width: "auto",
                zIndex: 0
              }, submitButton: {}, preview: {
                zIndex: 0
              },
              dropzoneReject: {borderColor: 'red', backgroundColor: '#ddaaaa'},
              inputLabel: (files, extra) =>
                (extra.reject ? {color: 'red'} :
                  {
                    color: '#0c6030',
                    fontSize: 18,
                    border: 'dashed lightGrey 2px',
                    borderRadius: 5,
                    padding: 5
                  }),
            }}
          />
        </div>
      </div>
    </>
  )
}
export default DocUpload;
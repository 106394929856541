import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {Tabs} from "antd";
import InstantPayment from '../Components/Stripe/InstantPayment';
import MembershipPayment from "../Components/Stripe/MembershipPayment";

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.


const stripePromise = loadStripe(
  'pk_live_51K8V7sGI0OEgB81vQotRtLrD8Rwkfx2vjTouiRnE910WbxJ1akBbDaeyZkY7nN6fKnPIheL0drDJsbQefZwkgYmH00dVJPcXZ6');
const {TabPane} = Tabs;

function Payment() {


  return (
    <div style={{maxWidth: 500, margin: "auto"}}>
      <Tabs defaultActiveKey="1">
        <TabPane tab={<span>Membership Dues</span>} key="1">
          <Elements stripe={stripePromise}>
            <MembershipPayment/>
          </Elements>
        </TabPane>
        <TabPane tab={<span>Other Payments</span>} key="2">
          <Elements stripe={stripePromise}>
            <InstantPayment/>
          </Elements>
        </TabPane>
      </Tabs>
    </div>
  );
};
export default Payment;
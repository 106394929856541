import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFile} from "@fortawesome/free-regular-svg-icons";
import TableOptions from "./TableOptions";
import {message} from "antd";
import React from "react";
import axios from "axios";


const deleteFile = (id, public_id) => {

  axios.delete('/docs', {
    params: {id, public_id}
  }).then(r => {
    message.success('File successfully deleted ', 2)
      .then(() =>
        message.info("Page will be refreshed", 2))
      .then(() => {
        window.location.reload(false);
      });
    console.log(r)
  }).catch(e => {
    console.log(e)
  });
}

function downloadFile(file) {
  if (!file) return;
  //attach a string inside url
  let startUrl = file.slice(0, file.indexOf('upload/') + 7);
  let endUrl = file.slice(file.indexOf('upload/') + 7);
  let fl_attachment = 'fl_attachment/';
  return startUrl + fl_attachment + endUrl;
}


export const columns = [
  {
    title: `File original name`,
    key: 'originalFileName',
    render: (text, record) => (
      <><FontAwesomeIcon
        style={{fontSize: 30, color: '#f4f4f4'}}
        icon={faFile}/>
        {' '}{record.originalFileName}
      </>),
  },
  {
    title: 'Size',
    dataIndex: 'size',
    key: 'size',
  },
  {
    title: 'Created',
    dataIndex: 'created',
    sorter: (a, b) => new Date(a.created) - new Date(b.created),
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <TableOptions href={downloadFile(record.url)} onClick={() => {
        downloadFile(record.url);
        message.success('file downloaded')
      }} record={record} onClick1={() => deleteFile(record.id, record.public_id)}/>
    ),
  },
];

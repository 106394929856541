import React, {useState} from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import {CARD_ELEMENT_OPTIONS, Label, Main, PayButton} from './CardSectionStyles';
import {Input, message} from "antd";
import {DollarOutlined, InfoCircleOutlined, UserOutlined} from '@ant-design/icons';
import {Auth} from "aws-amplify";
import {createCharge, createCustomerIfNotExists} from "./helper";
import LogoPng from "../LogoPNG";
import LoaderOverLay2 from "../LoaderOverLay2";

/*
for instant payment for any reason the amount is set by the user
 */
export default function InstantPayment() {

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(0);
  const [purposeOfPay, setPurposeOfPay] = useState('');
  const [enableButton, setEnableButton] = useState(true);

  const stripeConfig = {
    currency: 'USD',
    publishableApiKey: process.env.REACT_APP_KEY
  };


  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    setEnableButton(false);//prevent the button to click twice
    event.preventDefault();

    if (name.length < 3) {
      setEnableButton(true);
      message.warning('please enter a name')
      return;
    }

    if (purposeOfPay.length < 3) {
      setEnableButton(true);
      message.warning('please enter the purpose of pay')
      return;
    }
    if (amount < 1) {
      setEnableButton(true);
      message.warning('please enter an amount')
      return;
    }
    if (!stripe || !elements) {
      setEnableButton(true);
      message.warning('please enter a valid card')
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      setEnableButton(true);//enable the button again
      return;
    }  // card not valid

    try {
      setIsLoading(true);
      const u = await Auth.currentAuthenticatedUser();
      const user = {
        id: u.attributes.sub,
        name: name,
        email: u.attributes.email
      }

      await createCustomerIfNotExists(user, 'instantPayment');
      await createCharge(name, result, stripeConfig, amount,
        purposeOfPay + ' ' + name)

      setIsLoading(false);
      message.success('Your payment processed successfully!').then(() => {
        message.info('page will be refreshed').then(() => {
          window.location.reload(false);
        });
      });

    } catch (e) {
      console.log(e);
      message.error('Your payment could not be processed')
      setIsLoading(false);
    }
  };

  function onChangeAmount(e) {
    if (!e.target.validity.patternMismatch) {
      setAmount(e.target.value);
    }
  }


  return (
    <form onSubmit={handleSubmit}>
      <div className={'hover-border'} style={Main}>
        <div style={Label}>Billing Info<LogoPng/></div>
        <Input
          style={{marginBottom: 10}}
          placeholder="First and last name"
          prefix={<UserOutlined style={{color: "#aab7c4"}}/>}
          onChange={e => setName(e.target.value)}
        />
        <Input
          style={{marginBottom: 10}}
          placeholder="Purpose of payment"
          prefix={<InfoCircleOutlined style={{color: "#aab7c4"}}/>}
          onChange={e => setPurposeOfPay(e.target.value)}
        />
        <Input
          style={{marginBottom: 10}}
          placeholder="Amount"
          prefix={<DollarOutlined style={{color: "#aab7c4"}}/>}
          value={amount || ''}
          pattern="^-?[0-9]\d*\.?\d*$"
          onChange={onChangeAmount}
        />

        <CardElement options={CARD_ELEMENT_OPTIONS}/>
        <div style={{paddingTop: 20}}>

          <button style={
            !enableButton ? {
              padding: 10,
              backgroundColor: "#c4c4c4",
              border: "none",
              fontSize: 18,
              color: "white",
              borderRadius: 4
            } : PayButton
          }
                  disabled={!enableButton}>
            <span>Pay</span>
          </button>
          {isLoading && <LoaderOverLay2/>}
        </div>
      </div>
    </form>
  );
}
export const MainDiv = {
  backgroundColor: "#0c3c60",
  position: "sticky",
  top: 0,
  width: "100%",
  zIndex: 1,
  paddingTop: 20,
}

export const MainDiv2 = {
  backgroundColor: "#0c3c60",
  position: "sticky",
  top: 0,
  width: "100%",
  zIndex: 1,
  paddingTop: 20,
  paddingBottom: 20,
  marginBottom: 20
}
export const DivWrapper = {
  letterSpacing: "0.06em",
  font: "bold 25px/1.4em raleway,sans-serif",
  color: "white",
  margin: "auto",
  maxWidth: 1000,
  textAlign: 'center'
}
import {GET_MEETINGS} from "./meeting.types";

const initialState={
  data:{}
}

export const meetingsReducer = (state = initialState, action) => {

  switch (action.type) {
    case GET_MEETINGS:
      return {
        ...action.payload
      };

    default:
      return state;
  }
}

import React from 'react';
import {Auth} from "aws-amplify";
import {signOutUser} from "../../redux/user/user.actions";
import {useDispatch} from "react-redux";
import {Button} from "antd";

export const SignOutButton = () => {

  const dispatch = useDispatch();

  const signOutAmplify = async () => {
    try {
      await Auth.signOut();
      dispatch(signOutUser());
    } catch (e) {
      // console.log(e)
    }
  };

  return (
    <Button ghost
            style={{
              zIndex: 20,
              float: 'right',
              border: "none"
            }}
            onClick={signOutAmplify}> Sign out
    </Button>
  )
}
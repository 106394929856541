import React, {useState} from "react";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Input, message} from "antd";
import {Auth} from "aws-amplify";
import {createCharge, createCustomerIfNotExists} from "./helper";
import {CARD_ELEMENT_OPTIONS, Label, Main, PayButton} from "./CardSectionStyles";
import {DollarOutlined, InfoCircleOutlined, UserOutlined} from "@ant-design/icons";
import {Loader} from "@aws-amplify/ui-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink} from "@fortawesome/free-solid-svg-icons";
import {
  setRegistration,
  stopRegistration,
  successRegistration
} from "../../redux/meetingRegister/register.actions";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {useHistory} from "react-router-dom";

const RegistrationPayment = ({amount, purpose, location, date}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [affiliation, setAffiliation] = useState("");
  const [enableButton, setEnableButton] = useState(true);

  const stripeConfig = {
    currency: 'USD',
    publishableApiKey: process.env.REACT_APP_KEY
  };
  const {isRegistering} = useSelector(state => state.register);
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    setEnableButton(false);//prevent the button to click twice
    event.preventDefault();

    if (name.length < 3) {
      setEnableButton(true);
      message.warning('please enter a name')
      return;
    }

    if (amount < 1) {
      setEnableButton(true);
      message.warning('please enter an amount')
      return;
    }
    if (!stripe || !elements) {
      setEnableButton(true);
      message.warning('please enter a valid card')
      return;
    }
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      setEnableButton(true);
      return;
    }


    try {

      setIsLoading(true);
      const u = await Auth.currentAuthenticatedUser();
      const user = {
        id: u.attributes.sub,
        name: name,
        email: u.attributes.email
      }

      await createCustomerIfNotExists(user, 'instantPayment');
      await createCharge(name, result, stripeConfig, amount,
        purpose + ' ' + name)

      dispatch(setRegistration({
        name,
        affiliation,
        price: amount,
        date,
        location,
        isRegistering: true
      }));

      axios.post('/registration', {
        name,
        affiliation,
        price: amount,
        date,
        location,
      }).then(r => {
        // console.log(r.data)
      }).catch(e => {
        console.log(e)
      });

      setIsLoading(false);
      dispatch((successRegistration()));
      history.push('/success-message', {
        date: date,
        location: location,
        registeredUser: name
      })

    } catch (e) {
      console.log(e);
      message.error('Your payment could not be processed')
      setIsLoading(false);

      dispatch(stopRegistration());
    }
  };


  return isRegistering && (
    <form style={{marginTop:20}} onSubmit={handleSubmit}>
      <div className={'hover-border'} style={Main}>
        <div style={Label}>Annual Meeting
          <span
            className={'pointer'}
            style={{float: 'right'}}
            onClick={() => history.push('/meetings')}>
            x
          </span>
        </div>

        <Input
          style={{marginBottom: 10}}
          placeholder="First and last name"
          prefix={<UserOutlined style={{color: "#aab7c4"}}/>}
          onChange={e => setName(e.target.value)}
        />
        <Input
          style={{marginBottom: 10}}
          placeholder="Affiliation"
          prefix={<FontAwesomeIcon icon={faLink} className={'icon'}/>}
          onChange={e => setAffiliation(e.target.value)}
        />
        <Input
          style={{marginBottom: 10}}
          prefix={<InfoCircleOutlined style={{color: "#aab7c4"}}/>}
          value={purpose}
        />
        <Input
          style={{marginBottom: 10}}
          placeholder="Amount"
          prefix={<DollarOutlined style={{color: "#aab7c4"}}/>}
          value={amount}
        />

        <CardElement options={CARD_ELEMENT_OPTIONS}/>
        <div style={{paddingTop: 20}}>

          <button style={
            !enableButton ? {
              padding: 10,
              backgroundColor: "#c4c4c4",
              border: "none",
              fontSize: 18,
              color: "white",
              borderRadius: 4
            } : PayButton
          }
                  disabled={!enableButton}>
            <span>Pay</span>
          </button>
          {isLoading && <>
            <div
              style={{
                zIndex: 20,
                position: "fixed",
                top: 0, left: 0,
                backgroundColor:
                  "#e1e1e170",
                width: window.innerWidth,
                height: window.innerHeight
              }}
            >
              <Loader
                size="large"
                emptyColor="#108ee9"
                filledColor="#87d068"
                style={{position: "fixed", left: '45%', top: '50%', zIndex: 40}}
              /></div>
          </>
          }
        </div>
      </div>
    </form>
  )

}

export default RegistrationPayment;
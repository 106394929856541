import React from 'react';
import {Amplify} from 'aws-amplify';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";

import awsExports from './aws-exports';
import Payment from './Pages/Payment';
import Nav from "./Components/Nav/Nav";
import Lectures from "./Pages/Lectures";

import {useLoginStatus} from "./useLoginStatus";
import {useSelector} from "react-redux";
import useEvents from "./useEvents";
import Meetings from "./Pages/Meetings";
import MemberAuth from "./Components/MemberAuth/MemberAuth";
import AdminMeetings from "./Pages/AdminMeetings";
import AdminLectures from "./Pages/AdminLectures";
import {useMeeting} from "./useMeeting";
import RegisteredUsersPage from "./Pages/RegisteredUsersPage";
import DocksUploadPage from "./Pages/DocsUploadPage";
import DocsAccessPage from "./Pages/DocsAccessPage";
import RegistrationPage from "./Pages/RegistrationPage";
import SuccessPage from "./Pages/SuccessPage";
import Footer from "./Components/Footer";

Amplify.configure(awsExports);

const App = () => {

  useLoginStatus();
  useEvents();
  useMeeting();

  const {sub, isSignedIn} = useSelector(state => state.user)

  const isAdmin = sub === process.env.REACT_APP_PAVLOS_ID ||
    sub === process.env.REACT_APP_ANASTASIS_ID ||
    sub === process.env.REACT_APP_TYLER_ID ||
    sub === process.env.REACT_APP_PRESIDENT_ID;

  return !isSignedIn ? (
    <> <Nav/> <MemberAuth/></>
  ) : (
    <Router>
      <Nav/>
      <Switch>
        <Route exact path='/' component={Payment}/>
        <Route path='/lectures' component={Lectures}/>
        <Route path='/meetings' component={Meetings}/>
        <Route path='/registration' component={RegistrationPage}/>
        <Route path='/success-message' component={SuccessPage}/>


        {/*private routes================================*/}
        {isAdmin &&
        <>
          <Route path='/admin-upload-docs' component={DocksUploadPage}/>
          <Route path='/admin-access-docs' component={DocsAccessPage}/>
          <Route path='/admin-lectures' component={AdminLectures}/>
          <Route path='/admin-meeting' component={AdminMeetings}/>
          <Route path='/registered-users' component={RegisteredUsersPage}/>
        </>}
        {/*private routes================================*/}
      </Switch>
      <Footer/>
    </Router>
  )
}

export default (App);




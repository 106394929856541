import React from 'react';

import MeetingsComponent from "../Components/Meetings/Meetings.component";

const Meetings = () => {
  return(
    <MeetingsComponent/>
  )
}

export default Meetings;
import React, {useState} from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import {CARD_ELEMENT_OPTIONS, Label, Main, PayButton} from './CardSectionStyles';
import axios from "axios";
import {Input, Radio, Space} from "antd";
import {UserOutlined} from '@ant-design/icons';
import {message} from 'antd';
import {createCustomerIfNotExists} from "./helper";
import {Auth} from "aws-amplify";
import {createCharge} from './helper';
import LogoPng from "../LogoPNG";
import LoaderOverLay2 from "../LoaderOverLay2";

export default function MembershipPayment() {

  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [radioValue, setRadioValue] = useState();
  const [enableButton, setEnableButton] = useState(true);

  const stripeConfig = {
    currency: 'USD',
    publishableApiKey: process.env.REACT_APP_KEY
  };

  const handleSubmit = async (event) => {
    setEnableButton(false);//prevent the button to click twice
    event.preventDefault();

    if (!stripe || !elements) {
      setEnableButton(true);
      return;
    }
    if (name.length < 3) {
      setEnableButton(true);
      message.warning('please enter a name')
      return;
    }
    if (!radioValue) {
      setEnableButton(true);
      message.warning('please select a plan')
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      setEnableButton(true);//enable the button again
      return;
    }  // card not valid


    setIsLoading(true);
    const u = await Auth.currentAuthenticatedUser();

    const user = {
      id: u.attributes.sub,
      name: name,
      email: u.attributes.email
    }


    if (radioValue === 2) { // it is an one time payment
      await createCharge(
        name,
        result,
        stripeConfig,
        150,
        'membership one time payment, '
        + user.name + ', '
        + user.email).then(e => {
        setName('');
        setRadioValue('');
      }).then(() => {
        message.success('Your payment processed successfully!')
          .then(() => {
            message.info('page will be refreshed')
          })
          .then(() => window.location.reload(false));
        setIsLoading(false);
      });
      return;

    }

    // else it is subscription
    await createCustomerIfNotExists(user, "membership");

//=============function inside function ===================
    async function createSubscription() {
      await axios.post(
        '/subscription',
        {
          token: result.token.id,
          customer: user,
        }).then((r) => {

        if (r.data.createPaymentMethodError) {
          console.log(r.data.createPaymentMethodError);
          message.error('Your payment could not be processed')
          setIsLoading(false);
          return;

        }
        setIsLoading(false);
        message.success('Your payment processed successfully!')
          .then(() => {
            message.info('page will be refreshed')
          }).then(() => window.location.reload(false));
      });

    }

    await createSubscription();
  };


  return (
    <form id={'form'} onSubmit={handleSubmit}>
      <div style={Main} className={'hover-border'}>
        <div style={Label}>Membership <LogoPng/></div>
        <Input
          style={{marginBottom: 10}}
          placeholder="First and last name"
          prefix={<UserOutlined style={{color: "#aab7c4"}}/>}
          onChange={e => setName(e.target.value)}
        />
        <CardElement options={CARD_ELEMENT_OPTIONS}/>

        <Radio.Group
          style={{padding: 12}}
          onChange={(e) => setRadioValue(e.target.value)} value={radioValue}
        >
          <Space direction="vertical">
            <Radio value={1}>
              <h2>Auto billing yearly</h2>
              <p>Use this option to enroll to a $150 annual recurring membership payment.<br/>
                <i><small>You may cancel anytime by contacting the President</small></i>
              </p>

            </Radio>
            <Radio value={2}>
              <h2>One time payment</h2>
              <p>Use this option to submit a one time payment of $150</p>
            </Radio>
          </Space>
        </Radio.Group>

        <div style={{paddingTop: 2}}>
          <button style={
            !enableButton ? {
              padding: 10,
              backgroundColor: "#c4c4c4",
              border: "none",
              fontSize: 18,
              color: "white",
              borderRadius: 4
            } : PayButton
          }
                  disabled={!enableButton}>
            <span>Pay</span>
          </button>
          {isLoading && <LoaderOverLay2/>}
        </div>
      </div>
    </form>
  );
}
import React from "react";
import {Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import * as PropTypes from "prop-types";
import {useSelector} from "react-redux";

const RemoveToolTip = (props) => {

  const {sub} = useSelector(state => state.user)

  const isAdmin = sub === process.env.REACT_APP_PAVLOS_ID ||
    sub === process.env.REACT_APP_ANASTASIS_ID ||
    sub === process.env.REACT_APP_TYLER_ID ||
    sub === process.env.REACT_APP_PRESIDENT_ID;


  return isAdmin && <Tooltip title={`Permanently remove ${props.value.title}`}>
    <FontAwesomeIcon
      icon={faTrashAlt}
      style={{fontSize: 20, color: "red", cursor: "pointer", float: "right"}}
      onClick={props.onClick}
    />
  </Tooltip>;

}

RemoveToolTip.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func
};

export default RemoveToolTip;
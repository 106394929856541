import React from 'react';
import SuccessComponent from "../Components/success.component";
import {useHistory} from "react-router-dom";

function SuccessPage() {
  const history = useHistory();
  return (
    <><SuccessComponent
      title={`Thank you ${history.location.state.registeredUser}!`}
      comment={`See you at ${history.location.state.location}
       on ${history.location.state.date}`}
    /></>
  );
}

export default SuccessPage;
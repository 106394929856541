import {Popconfirm, Space} from "antd";
import {DownloadOutlined, EyeOutlined} from "@ant-design/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import React from "react";
import * as PropTypes from "prop-types";

export default function TableOptions(props) {

  return <Space size="middle">
    <a href={props.href} rel="noopener noreferrer">
      <DownloadOutlined key="setting" onClick={props.onClick}/></a>
    <a href={props.record.url} target="_blank" rel="noopener noreferrer"><EyeOutlined key="view"/></a>
    <Popconfirm
      title="Are you sure to delete this file?"
      onConfirm={props.onClick1}

      okText="Yes"
      cancelText="No"
    >
      <FontAwesomeIcon style={{color:'orangered'}} icon={faTrashAlt}/>
    </Popconfirm>
  </Space>;
}
TableOptions.propTypes = {
  href: PropTypes.any,
  onClick: PropTypes.func,
  record: PropTypes.any,
  onClick1: PropTypes.func
};
import {SignOutButton} from "./SignOutButton";
import {Dropdown, Menu} from "antd";
import {DivWrapper, MainDiv, MainDiv2} from "./navStyles";
import React from "react";
import {useHistory} from 'react-router-dom';
import {useSelector} from "react-redux";
import {DownOutlined} from "@ant-design/icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFileVideo, faFile, faCalendar, faSearch} from '@fortawesome/free-solid-svg-icons'
import {faUpload, faUser} from "@fortawesome/free-solid-svg-icons";


const Nav = () => {

  const {sub, isSignedIn} = useSelector(state => state.user);

  const isAdmin = sub === process.env.REACT_APP_PAVLOS_ID ||
    sub === process.env.REACT_APP_ANASTASIS_ID ||
    sub === process.env.REACT_APP_TYLER_ID ||
    sub === process.env.REACT_APP_PRESIDENT_ID;

  const history = useHistory();

  const handleNav = (e) => {
    history.push(e.key)
  }

  function handleDoc(doc) {

    if (doc.key.endsWith('U')) {
      const key = doc.key.substr(0, doc.key.length - 1);
      history.push('admin-upload-docs', {
        doc: key
      });
    }
    if (doc.key.endsWith('S')) {
      const key = doc.key.substr(0, doc.key.length - 1);
      history.push('admin-access-docs', {
        doc: key
      });
    }
  }

  const menu = (
    <Menu>

      <Menu.Item key={'admin-lectures'} onClick={handleNav}>
        <FontAwesomeIcon className={'iconGreen'} icon={faFileVideo}/> Upload Lecture
      </Menu.Item>
      <Menu.Item key={'admin-meeting'} onClick={handleNav}>
        <FontAwesomeIcon className={'iconGreen'} icon={faCalendar}/> Upload Meeting
      </Menu.Item>
      <Menu.Item key={'registered-users'} onClick={handleNav}>
        <FontAwesomeIcon className={'iconGreen'} icon={faUser}/> Event registered users
      </Menu.Item>

      <Menu.SubMenu key={"dummy3"} onClick={handleDoc}
                    title={<><FontAwesomeIcon icon={faFile} className={'iconGreen'}/> Docs</>}>

        <Menu.SubMenu key={'dummy2'} title={<><FontAwesomeIcon icon={faUpload} className={'iconGreen'}/>Upload</>}>
          <Menu.Item key={'taxesU'}>Taxes</Menu.Item>
          <Menu.Item key={'statementsU'}>Statements</Menu.Item>
          <Menu.Item key={'paidReceiptsU'}>Paid Receipts</Menu.Item>
          <Menu.Item key={'unpaidReceiptsU'}>Unpaid Receipts</Menu.Item>
          <Menu.Item key={'otherU'}>Other</Menu.Item>
        </Menu.SubMenu>


        <Menu.SubMenu key={'dummy'} title={<><FontAwesomeIcon icon={faSearch} className={'iconGreen'}/> Access</>}>
          <Menu.Item key={'taxesS'}>Taxes</Menu.Item>
          <Menu.Item key={'statementsS'}>Statements</Menu.Item>
          <Menu.Item key={'paidReceiptsS'}>Paid Receipts</Menu.Item>
          <Menu.Item key={'unpaidReceiptsS'}>Unpaid Receipts</Menu.Item>
          <Menu.Item key={'otherS'}>Other</Menu.Item>
        </Menu.SubMenu>

      </Menu.SubMenu>
    </Menu>
  );

  return !isSignedIn ? (
    <div style={MainDiv2}>
      <div style={DivWrapper}>
        <span style={{width: '100%'}}>
          North Carolina Society for Surgery of the Hand
        </span>
      </div>
    </div>
  ) : (
    <div style={MainDiv}>
      <div style={DivWrapper}>
        <span style={{width: '100%'}}>
          North Carolina Society for Surgery of the Hand</span>
        <p></p>
        {isSignedIn && <SignOutButton/>}
        <Menu mode="horizontal">
          <Menu.Item key="/" onClick={handleNav}>
            Payments
          </Menu.Item>
          <Menu.Item key="/meetings" onClick={handleNav}>
            Meetings
          </Menu.Item>
          <Menu.Item key="/lectures" onClick={handleNav}>
            Lectures
          </Menu.Item>
          {isAdmin &&
          <>
            <Menu.Item key="/admin">
              <Dropdown overlay={menu}>
                <div className="ant-dropdown-link"
                     onClick={e => e.preventDefault()}>
                  Admin <DownOutlined/>
                </div>
              </Dropdown>
            </Menu.Item>
          </>}
        </Menu>

      </div>
    </div>
  )
}
export default Nav;
import {useEffect, useState} from "react";
import {Hub} from "aws-amplify";
import {useDispatch} from "react-redux";

import {signInUser} from "./redux/user/user.actions";


const useEvents = () => {
  const dispatch = useDispatch();
  const [event, setEvent] = useState('');

  useEffect(() => {

    Hub.listen(/.*/, (data) => {
      const currentEvent = data.payload.event;
      setEvent(currentEvent);

      if (currentEvent === 'signUp') {
      }

      try {
        const userAttributes = data.payload.data.attributes || {};
        if (userAttributes) {
          const user = {
            sub: userAttributes.sub,
            email: userAttributes.email,
          };

          if (currentEvent === 'signIn') {
            setTimeout(() => {
                dispatch(signInUser(user));
              }
              , 0);
            //
            // if (user.sub === 'b79b157b-ef25-4d35-ae68-f341cf16543b') {
            //   notification.info({
            //     icon: <CheckOutlined style={{color: '#4bf140'}}/>,
            //     description: 'welcome back admin ' + user.email,
            //     duration: '4',
            //     style: {
            //       backgroundColor: "#404040",
            //       color: "#4bf140"
            //     }
            //   });
            // }
          }

          if (currentEvent === 'signOut') {
            //
          }
        }
      } catch (e) {
      }
    });

    return () => {
      Hub.remove('auth', () => {
      });
    };
  }, [dispatch]);


  return {
    event,
  }
};

export default useEvents;
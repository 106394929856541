import React from "react";
import {Loader} from "@aws-amplify/ui-react";

export default function LoaderOverLay2() {
  return <div
    style={{
      zIndex: 20,
      position: "fixed",
      top: 0, left: 0,
      backgroundColor:
        "#e1e1e170",
      width: window.innerWidth,
      height: window.innerHeight
    }}
  >
    <Loader
      size="large"
      emptyColor="lightblue"
      filledColor="darkblue"
      style={{position: "fixed", left: "45%", top: "50%", zIndex: 40}}
    /></div>;
}

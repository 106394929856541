import {
  GET_REGISTRATION,
  SET_REGISTRATION,
  START_REGISTRATION,
  STOP_REGISTRATION,
  SUCCESS_REGISTRATION
} from "./register.types";


export const setRegistration = (registration) => ({
  type: SET_REGISTRATION,
  payload: registration
})

export const getRegistration = (registration) => ({
  type: GET_REGISTRATION,
  payload: registration
})

export const stopRegistration = () => ({
  type: STOP_REGISTRATION,
})

export const startRegistration = () => ({
  type: START_REGISTRATION,
});

export const successRegistration = () => ({
  type: SUCCESS_REGISTRATION,
})
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {Auth} from "aws-amplify";
import {signInUser} from "./redux/user/user.actions";

export const useLoginStatus = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const getCurrentAuthUser = async () => {
      try {
        const {attributes} = await Auth.currentAuthenticatedUser();
        const authUser = {
          sub: attributes.sub,
          email: attributes.email
        };
        dispatch(signInUser(authUser));
      } catch (e) {
        console.log(e)
      }
    };
    getCurrentAuthUser();
    return () => {
    }
  }, [dispatch]);

}
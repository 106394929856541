import React from 'react';

import {Button, Result} from "antd";
import {useHistory} from "react-router-dom";

const SuccessComponent = ({title, comment}) => {
 const history = useHistory()

  return <Result
    status="success"
    title={title}
    subTitle={comment}
    extra={
      <Button
        type="primary"
        onClick={() => history.push('/meetings')}
      >
        OK
      </Button>}
  />
}
export default SuccessComponent;
import {LabelLeft, Main} from "./meetingsStyles";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendar} from '@fortawesome/free-regular-svg-icons'
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Card, message} from "antd";
import {startRegistration} from "../../redux/meetingRegister/register.actions";
import MeetingsList from "./MeetingsList";
import axios from "axios";
import {useHistory} from "react-router-dom";

require('dotenv').config();


const MeetingsComponent = () => {

  const meetings = useSelector(state => state.meetings);
  const dispatch = useDispatch();
  const history = useHistory();


  function deleteMeeting(id) {
    axios.delete('/meeting', {
      params: id
    }).then(r => {
      message.success('Meeting successfully deleted ', 2)
        .then(() =>
          message.info("Page will be refreshed", 2))
        .then(() => {
          window.location.reload(false);
        });
      // console.log(r)
    }).catch(e => {
      console.log(e)
    });
  }

  return  (
    <div style={Main}>
      <Card title={<h1 style={LabelLeft} className={'label-responsive'}>
        <FontAwesomeIcon icon={faCalendar}/> Annual Meetings</h1>}>
        {Object.entries(meetings).length > 0
        && Object.entries(meetings).map(([key, value]) => (
          <div key={key}>
            <MeetingsList
              value={value}
              onClickRegister={() => {
                dispatch(startRegistration())
                history.push('/registration', {
                  amount:value.price,
                  location:value.location,
                  date:value.date,
                  purpose: 'Registration Payment'
                })
              }}
              onClickDelete={() => deleteMeeting(value._id)}/>
          </div>
        ))}
      </Card>
    </div>
  )
}
export default MeetingsComponent;
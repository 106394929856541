import {Spin} from "antd";
import React from "react";

export default function LoaderOverlay({text}) {
  return <div style={{
    zIndex: 20,
    position: "fixed",
    top: 0, left: 0,
    backgroundColor:
      "#e1e1e170",
    width: window.innerWidth,
    height: window.innerHeight
  }}>
    <Spin tip={<p style={{
      background: 'ghostwhite',
      padding: 18
    }}>{text}</p>}
          style={{
            position: "fixed",
            top: "50%",
            left: "45%",
            zIndex: 22
          }}
          size={"large"}
    />
  </div>;
}

import {Label, Main} from "../Stripe/CardSectionStyles";
import {Input, message, Popconfirm} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, {useState} from "react";
import axios from "axios";

const CreateLecture = () => {
  const stylesInput = {
    marginBottom: 10, padding: 10, borderRadius: 5,
    width: '-webkit-fill-available'
  }

  const [state, setState] = useState({
    title: '',
    url: '',
    otherComments: '',
  });

  const updateField = e => {
    e.persist();
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  async function confirm() {
    await axios.post('/lecture',
      {
        state
      })
      .then(() => {
        message.success(
          'Lecture successfully created', 2)
          .then(() => {
            message.info(`Page will be refreshed `,
              2)
          })
          .then(() => {
            window.location.reload(false);
          })
      })
      .catch(e => {
        console.log(e)
      });
  }

  return (
    <>
      <form style={{marginTop: 20}}>
        <div className={'hover-border'} style={Main}>
          <div style={Label}>Create a new lecture</div>
          <Input
            style={stylesInput}
            value={state.title}
            name={'title'}
            placeholder={'Title'}
            onChange={updateField}
          />
          <Input
            style={stylesInput}
            name={'url'}
            onChange={updateField}
            placeholder={'URL'}
          />


          <TextArea
            style={stylesInput}
            name={'otherComments'}
            placeholder={"Description"}
            onChange={updateField}
          />
          <Popconfirm placement="top" title={"Free of errors?"}
                      onConfirm={confirm}
                      okText="Yes" cancelText="No">

            <button
              className="amplify-button "
              data-fullwidth="true"
              data-loading="false"
              data-variation="primary"
              type="submit"
              style={{background: "#0c6030"}}
            >
              Submit
            </button>

          </Popconfirm>
        </div>
      </form>

    </>
  )
}

export default CreateLecture;
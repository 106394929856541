import React from 'react';
import {Button, Table} from "antd";
import {useEffect, useState} from "react";
import axios from "axios";
import {message} from "antd";
import LoaderOverLay2 from "../LoaderOverLay2";

const RegisteredUsers = () => {

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: text => <i>{text}</i>,
    },
    {
      title: 'Affiliation',
      className: 'column-money',
      dataIndex: 'affiliation',
    },
    {
      title: 'Location',
      dataIndex: 'location',
    },
    {
      title: 'Event Date',
      dataIndex: 'date',
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
    },
  ];

  const [state, setState] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const arr = [];
    setIsLoading(true)
    axios.get('/registration')
      .then(r => {

        Object.entries(r.data).forEach(([key, d]) => {

          arr.push({
            key: key,
            name: d.name,
            affiliation: d.affiliation,
            location: d.location,
            date: d.date
          })
        });
      }).then(() => {
      setState(arr);
      setIsLoading(false);
    })

  }, []);


  async function removeAll() {
    axios.delete('/registration').then((r) => {

      message.success("Successfully deleted", 2)
        .then(() =>
          message.info("Page will be refreshed", 2))
        .then(() => {
          window.location.reload(false);
        });
    }).catch(() => {
      message.error('An error occurred')
    });
  }

  return (<>{isLoading && <LoaderOverLay2/>}
      <Table
        columns={columns}
        dataSource={state}
        bordered
        title={() => <h2>Registered Members</h2>}
        footer={() => state.length === 0 ? <></> :
          <Button
            type={'ghost'}
            onClick={removeAll}
          >
            Delete All
          </Button>}
      /></>

  )

}

export default RegisteredUsers
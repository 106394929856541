import React from 'react';

function Footer() {
  return (
    <><div className={'spacer'}> </div>
  <div className={'footerContainer'}>
    <i>  &#169; {new Date().getFullYear()} NCSSH</i>
  </div></>
  );
}

export default Footer;


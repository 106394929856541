import RegisterMeetingButton from "./RegisterMeetingButton";
import RemoveToolTip from "./RemoveToolTip";
import * as PropTypes from "prop-types";
import React from "react";
import {Card} from "antd";

const MeetingsList = (props) => {

  return (
    <Card
      type="inner"
      title={
        <span style={{fontSize: 20}}>{props.value.title}  </span>
      }
      extra={
        <RemoveToolTip
          value={props.value}
          onClick={props.onClickDelete}
        />}
    >
      <div>
        <span style={{fontSize: 20}}>
          <b>Location: </b>
          <small>{props.value.location}</small>
        </span>
        <p style={{fontSize: 20}}>
          <b>Date:</b> <small>{props.value.date}</small>
        </p>
        <p style={{fontSize: 20}}>
          <b>Description:</b> <small>{props.value.otherComments}</small>
        </p>
        <p style={{fontSize: 20}}>
          <b>Price:</b> <small>${props.value.price}</small>
        </p>
      </div>
      <div style={{textAlign: "center", marginTop: 30}}>
        <RegisterMeetingButton onClick={props.onClickRegister}/>
      </div>
    </Card>

  )
}

MeetingsList.propTypes = {
  value: PropTypes.any,
  onClickRegister: PropTypes.func,
  onClickDelete: PropTypes.func
};

export default MeetingsList;
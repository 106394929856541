import React from 'react';

import DisplayLectures from "../Components/LecturesAdmin/DisplayLectures";

const Lectures = () => {
  return(
    <DisplayLectures/>
  )
}

export default Lectures;
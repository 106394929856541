import React from "react";
import {Loader} from "@aws-amplify/ui-react";


export default function LoaderUp() {
  return <Loader
    variation="linear"
    emptyColor="#108ee9"
    filledColor="#87d068"
    height={2}
    style={{position: "absolute", margin: -47}}
  />;
}
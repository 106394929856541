import {RegisterButton} from "./meetingsStyles";
import * as PropTypes from "prop-types";
import React from "react";

const  RegisterMeetingButton=(props)=> {
  return <button
    id={"reg_btn"}
    type="submit"
    style={RegisterButton}
    onClick={props.onClick}
  >
    REGISTER
  </button>;
}

RegisterMeetingButton.propTypes = {onClick: PropTypes.func};

export default RegisterMeetingButton;
import {useEffect} from "react";
import axios from "axios";
import {getMeetings} from "./redux/meeting/meetings.actions";
import {useDispatch} from "react-redux";

export const useMeeting = () => {

  const dispatch = useDispatch();

  useEffect(() => {

    async function f() {
      axios.get('/meeting/list', {
        data: "test"
      }).then(r => {
        dispatch(getMeetings(r.data));
      })
        .catch(e=>alert('an error occurred'))
    }
    f();

  }, [dispatch]);
  return {}
}
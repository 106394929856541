import React, {useEffect, useState} from "react";
import {LabelLeft, Main} from "../Meetings/meetingsStyles";
import {Card, message, Modal, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVideo} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import 'react-html5video/dist/styles.css';
import {useDispatch, useSelector} from "react-redux";
import {muteVideo, unmuteVideo} from "../../redux/video/video.reducer";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import LoaderUp from "../DocsAdmin/LoaderUp";


const DisplayLectures = () => {
  const [state, setState] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [urlId, setUrlId] = useState('');
  const {muting} = useSelector(state => state.video);
  const dispatch = useDispatch();
  const {sub} = useSelector(state => state.user)

  const isAdmin = sub === process.env.REACT_APP_PAVLOS_ID ||
    sub === process.env.REACT_APP_ANASTASIS_ID ||
    sub === process.env.REACT_APP_TYLER_ID ||
    sub === process.env.REACT_APP_PRESIDENT_ID;


  useEffect(() => {
    const arr = [];
    setIsLoading(true)
    axios.get('/lecture')
      .then(r => {
        Object.entries(r.data).forEach(([key, d]) => {
          arr.push({
            key: key,
            id: d._id,
            title: d.title,
            url: d.url,
            otherComments: d.otherComments,
            createdAt: d.createdAt,
          })
        });
      }).then(() => {
      setState(arr);
      setIsLoading(false);
    })

  }, []);

  function showVideo(url) {
    const id = youtube_parser(url);
    setUrlId(id.toString())
    setVisible(true)
    dispatch(unmuteVideo())

  }

  function youtube_parser(url) {
    const regExp = /^https?:\/\/(?:www\.youtube(?:-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*&)?vi?=|&vi?=|\?(?:.*&)?vi?=)([^#&?\n\/<>"']*)/i;
    const match = url.match(regExp);
    return (match && match[1].length === 11) ? match[1] : false;
  }

  function deleteLecture(id) {

    axios.delete('/lecture', {
      params: id
    }).then(r => {
      message.success('Lecture successfully deleted ', 2)
        .then(() =>
          message.info("Page will be refreshed", 2))
        .then(() => {
          window.location.reload(false);
        });
      // console.log(r)
    }).catch(e => {
      console.log(e)
    });
  }


  return (<> {isLoading && <LoaderUp/>}
      <div style={Main}>
        <Card title={
          <h1 style={LabelLeft}><FontAwesomeIcon icon={faVideo}/> Lectures</h1>}>

          {Object.entries(state).length > 0
          && Object.entries(state).map(([key, value]) => (
            <Card
              key={key}
              style={{marginBottom: 16}}
              type="inner"
              title={<span style={{fontSize: 20}}>{value.title}  </span>}
              extra={
                <FontAwesomeIcon
                  style={{color: "green", cursor: "pointer"}}
                  icon={faVideo}
                  onClick={() => showVideo(value.url)}
                />
              }
            >
              <h3>Description</h3>
              <p>
                {value.otherComments}
              </p>
              <Modal
                title={<span></span>}
                centered
                style={{paddingTop: 10}}
                visible={visible}
                onCancel={() => {
                  dispatch(muteVideo())
                  setTimeout(() => {
                    setVisible(false)
                  }, 0.1)
                }}
                width={1000}
                footer={null}
              >
                <div className='video-responsive'>
                  <iframe
                    id={'video'}
                    title={key}
                    width="420"
                    height="315"
                    src={`https://www.youtube.com/embed/${urlId}?&mute=${muting}`}
                  >
                  </iframe>
                </div>
              </Modal>
              {isAdmin &&
              <Tooltip title={`Permanently remove ${value.title}`}>
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  style={{fontSize: 20, color: "red", cursor: "pointer", float: "right"}}
                  onClick={() => {
                    deleteLecture(value.id)
                  }}
                />
              </Tooltip>}
            </Card>
          ))}
        </Card>
      </div>
    </>
  )
}

export default DisplayLectures;

const initialState = {
  muting:0,
};

export const muteVideo =()=>({
  type:"MUTE"
})

export const unmuteVideo =()=>({
  type:"UNMUTE"
})

const videoReducer = (state = initialState, action) => {

  switch (action.type) {

    case "MUTE":
      return {
        muting: 1
      };
    case "UNMUTE":
      return {
        muting: 0
      };

    default:
      return state;
  }
};

export default videoReducer;
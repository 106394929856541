import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Alert, Input, Menu, Table,} from "antd";
import './access.css';
import axios from "axios";
import {columns} from "./TableColumns";
import {formatFileSize} from "./docsSearchHelper";
import {useDispatch, useSelector} from "react-redux";
import {filterData, resetData} from "../../../redux/searchBox/searchBox.actions";
import {SearchOutlined} from "@ant-design/icons";
import LoaderOverLay2 from "../../LoaderOverLay2";


function DocsAccess() {
  const [loading, setLoading] = useState(false);
  const [docs, setDocs] = useState([])
  const [isSearching, setIsSearching] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [current, setCurrent] = useState('');
  const {filteredData} = useSelector(state => state.search);
  const dispatch = useDispatch();


  const history = useHistory();

  if (history.location.state === undefined) {
    history.push('/');
  }

  function getDocs(category) {
    const arr = [];
    setLoading(true)
    axios.get('/docs', {
      params: {
        category: category,
        sortType: -1
      }
    }).then(r => {
      Object.entries(r.data).forEach(([key, d]) => {
        arr.push({
          key: key,
          id: d._id,
          size: formatFileSize(d.size, 1),
          public_id: d.public_id,
          originalFileName: d.originalFileName,
          url: d.url,
          created: new Date(d.createdAt.toString()).toUTCString()
        });
      })
    }).then(() => {
      setDocs(arr);
      setLoading(false);
    }).catch(e => {
      console.log(e)
      setLoading(false);
    });
  }

    useEffect(() => {
      if(history.location.state !== undefined){
        setCurrent(history.location.state.doc)
        getDocs(history.location.state.doc);
      }
    }, [history.location.state !== undefined && history.location.state.doc]);


  function handleClick(e) {
    setCurrent(e.key)
    dispatch(resetData());
    setIsSearching(false)
    setInputValue('')
    getDocs(e.key);
  }


  const handleSearch = (e) => {
    e.persist()
    if (e.target.value.length > 0) {
      setIsSearching(true)
    } else {
      setIsSearching(false)
    }
    const filtered = docs.filter(data =>
      Object.keys(data)
        .some(k => data[k].toString().toLowerCase()
          .includes((e.target.value).toLowerCase())));
    dispatch(filterData(filtered))
  }

  return (
    <>
      {loading && <LoaderOverLay2/>}

      <div style={{position: '', margin: 'auto', maxWidth: 1000}}>
        <Menu
          onClick={handleClick}
          mode="horizontal"
          style={{fontSize: 12}}
          selectedKeys={[current]}
        >
          <Menu.Item key="taxes">Taxes</Menu.Item>
          <Menu.Item key="statements">Statements</Menu.Item>
          <Menu.Item key="paidReceipts">Paid Receipts</Menu.Item>
          <Menu.Item key="unpaidReceipts">Unpaid Receipts</Menu.Item>
          <Menu.Item key="other">Other</Menu.Item>
        </Menu>
        <Input
          id={'search'}
          value={inputValue}
          bordered={false}
          size="small"
          placeholder={`file name`}
          prefix={<SearchOutlined/>}
          onChange={(e) => {
            handleSearch(e);
            setInputValue(e.target.value)
          }}
        />
        {(isSearching && filteredData.length === 0) &&
        <Alert message="No file name matches your search" type="warning" showIcon/>}
        <Table scroll={{x: true}} columns={columns} dataSource={
          filteredData.length === 0 ? docs : filteredData
        }/>
      </div>
    </>
  );
}

export default DocsAccess;
/**
 * Use the CSS tab above to style your Element's container.
 */
import './Styles.css'

export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

export const PayButton={
  padding: 10,
  backgroundColor: "#0c6030",
  border: "none",
  fontSize: 18,
  color: "white",
  borderRadius: 4
}
export const PayButtonProgress={
  padding: 10,
  backgroundColor: "#0c6030",
  border: "none",
  fontSize: 18,
  color: "white",
  borderRadius: 4,
  cursor:'progress'
}

export const Label = {
  color: '#2490ff',
  fontSize: 30,
  paddingBottom: 20
}

export const Main = {
  maxWidth: 400,
  margin: 'auto',
  padding: 30,
  border: '1px solid #f4f4f4'
  // boxShadow: "-6px 6px 39px -12px rgba(0,0,0,0.27)"
}

